// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-profilfelder-index-js": () => import("./../../../src/pages/profilfelder/index.js" /* webpackChunkName: "component---src-pages-profilfelder-index-js" */),
  "component---src-pages-ueber-js": () => import("./../../../src/pages/ueber.js" /* webpackChunkName: "component---src-pages-ueber-js" */),
  "component---src-templates-aktivitaet-template-js": () => import("./../../../src/templates/aktivitaetTemplate.js" /* webpackChunkName: "component---src-templates-aktivitaet-template-js" */),
  "component---src-templates-kompetenzfeld-template-js": () => import("./../../../src/templates/kompetenzfeldTemplate.js" /* webpackChunkName: "component---src-templates-kompetenzfeld-template-js" */),
  "component---src-templates-meldung-template-js": () => import("./../../../src/templates/meldungTemplate.js" /* webpackChunkName: "component---src-templates-meldung-template-js" */),
  "component---src-templates-saeule-template-js": () => import("./../../../src/templates/saeuleTemplate.js" /* webpackChunkName: "component---src-templates-saeule-template-js" */)
}

